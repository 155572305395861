import axios from "src/utils/axios";

export const getAllSchoolList = async () => {
  const { data } = await axios("school/list");
  return data.data;
};
export const ordersSchool = async ({ queryKey, signal }) => {
  const { data } = await axios("orders/school" + queryKey[1]);
  return data.data;
};

export const ordersList = async ({ queryKey, signal }) => {
  const req = {
    schoolID: queryKey[1],
    packageID: queryKey[2] === "" ? undefined : +queryKey[2],
    status: queryKey[3] === "" ? undefined : queryKey[3],
    fromDate: queryKey[4] ?? undefined,
    toDate: queryKey[5] ?? undefined,
  };
  const { data } = await axios.post("orders/list", req);
  return data.data;
};

export const ordersSingleOrder = async ({ queryKey, signal }) => {
  const orderNo = queryKey[1];
  const { data } = await axios.get(`admin/order/singleOrder?order_no=${orderNo}`, { signal });
  return data.data;
};

export const fetchInvoiceData = async (orderID) => {
  const { data } = await axios.post("/invoice/view", { order_id: orderID });
  return data?.data[0];
};


export const masterGetProduct = async ({ pageIndex, pageSize }) => {
  
  const { data } = await axios.post(
    `master/products/get`, 
    {
      limit: pageSize, page: pageIndex + 1 
    });

  return data;
};
export const addProductVariation = async (payload) => {
  const response = await axios.post("master/update/addvariation", payload);
  return response.data;
};


export const masterGetData = async ({ queryKey, signal }) => {
  const req = {
    status: queryKey[1] === "" ? '' : queryKey[1],
    fromDate: queryKey[2] ?? '',
    toDate: queryKey[3] ?? '',
  };

  const { data } = await axios.get(
    `admin/order/getOrders?status=${req.status}&startDate=${req.fromDate}&endDate=${req.toDate}`,
    { signal }
  );

  // Check if no orders found and return an empty array if so
  if (data?.message === "No order found") {
    return [];
  }

  return data?.data || [];
};


export const masterGetDataByid = async ({ queryKey, signal }) => {
  const { data } = await axios.post(
    "admin/order/getOrders",
    {
      tableName: queryKey[1],
      page: +queryKey[2] + 1,
      limit: queryKey[3],
      where: [{ field: queryKey[4], operator: "=", value: queryKey[5] }],
    },
    { signal }
  );
  return data?.data[0] || [];
};

export const addProduct = async (data) => {
  const { tableData, category_id, ...rest } = data;
  const cleanedData = {
    ...rest,
    category_id: category_id?.id,
    products: undefined,
    tableData: undefined,
  };

  const { data: responseData } = await axios.post("master/products/add", cleanedData);
  return responseData;
};

export const updateProduct = async (data) => {
  const { product_l1_l2_id, id, qty, price, tax } = data; 

  const requestBody = { product_l1_l2_id, id, qty, price, tax }; 

  try {
    const { data: responseData } = await axios.post(
      'master/update/update/product_update',
      requestBody, 
    );
    return responseData;
  } catch (error) {
    console.error("Error updating product:", error); 
    throw error;
  }
};


export const fetchProductList = async () => {
  const response = await axios.get('product/listview/products');
  return response.data;
};


export const testimonialAddData = async ({ req, isEditMode }) => {
  const endpoint = isEditMode
    ? "master/update/update/testimonials"
    : "testimonials/add";

  const { data } = await axios.post(endpoint, req.fieldsArr[0]);
  return data.data;
};


export const fetchTestimonials = async ({ pageIndex, pageSize }) => {
  const { data } = await axios.get("/testimonials/view", { limit: pageSize, page: pageIndex + 1 });
  return data;
};

export const fetchTaxRates = async () => {
  const { data } = await axios.post("master/get/tax", {});
  return data.data || [];
};



export const fetchGetCategories = async ({ pageIndex, pageSize }) => {
  const { data } = await axios.post("master/get/category", { limit: pageSize, page: pageIndex + 1 });
  return data.data.map((cat) => ({
    id: cat.id,
    label: cat.category_name,
  }));
};

export const addVariation = async (data) => {
  const response = await axios.post("master/add/variation", data);
  return response.data; 
};

export const updateVariation = async (data) => {
  const response = await axios.post('master/update/update/variation', data);
  return response.data;
};


export const masterGetVariation = async ({ pageIndex, pageSize }) => {
  const { data } = await axios.post("master/get/variation", { tableName: "variation", limit: pageSize, page: pageIndex + 1 });
  return data;
};

export const masterGetDataa = async ({ pageIndex, pageSize }) => {
  const { data } = await axios.post(
    "master/get/category",
    { limit: pageSize, page: pageIndex + 1 },
  );
  return data;
};

export const fetchDescriptionLabels = async ({ pageIndex, pageSize }) => {
  const { data } = await axios.post("master/get/description_label", { limit: pageSize, page: pageIndex + 1 });
  return data;
};


export const addDescriptionLabel = async (labelName) => {
  const { data } = await axios.post("master/add/description_label", { label_name: labelName });
  return data;
};

export const updateDescriptionLabel = async (id, labelName) => {
  const { data } = await axios.post("master/update/update/description_label", {
    id,
    label_name: labelName,
  });
  return data;
};

export const masterSavePincode = async ({ req, isEditMode }) => {
  const endpoint = isEditMode ? "master/update/update/pincode" : "master/add/pincode";
  const { data } = await axios.post(endpoint, req);
  return data;
};

export const masterGetPincodeData = async ({ pageIndex, pageSize }) => {
  const { data } = await axios.post("master/get/pincode", { limit: pageSize, page: pageIndex + 1 });
  return data;
};

export const fetchVariations = async () => {
  const { data } = await axios.post("master/get/variation", { limit: "100", page: "1" });
  return data.data;
};

export const masterPromoCodeAddData = async (req) => {
  const response = await axios.post("master/add/promocode", req.fieldsArr[0]);
  return response.data;
};

export const masterPromoCodeUpdateData = async (req) => {
  const response = await axios.post("master/update/update/promocode", req.fieldsArr[0]);
  return response.data;
};

export async function masterPromoCodeGetData({ pageIndex, pageSize }) {
  const { data } = await axios.post("master/get/promocode", { limit: pageSize, page: pageIndex + 1 });
  return data;
}

export const addCategory = async ({ req, isEditMode }) => {
  const endpoint = isEditMode ? "master/update/update/category" : "master/add/category";
  const { data } = await axios.post(endpoint, req);
  return data.data;
};


export const ordersUpdate = async ({ order_no, status, order_product_id }) => {
  const { data } = await axios.post('admin/order/update', {
    order_no,
    status,
    order_product_id, 
  });

  return data;
};

export const orderslabel = async (orderId) => {
  const { data } = await axios.post("orders/label", orderId);
  return data.data;
};

export const masterDeleteData = async ({ signal, req }) => {
  const { tableName, id } = req;
  const url = `/master/delete/${tableName}/${id}`;

  const { data } = await axios.delete(url, { signal }); 
  return data.data;
};

export const addressStates = async ({ signal }) => {
  const { data } = await axios(`/address/states`, { signal });
  return data.data;
};
export const studentGetAll = async ({ signal }) => {
  const { data } = await axios(`/master/student/get`, { signal });
  return data.data;
};
export const uploadImage = async (type, req) => {
  const { data } = await axios.post(`/upload/image/${type}`, req, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
export const reportNewAndPendingOrder = async (req) => {
  const { data } = await axios(
    `report/newAndPendingOrder/${req.schoolID}/${req.initialDate}/${req.endDate}`
  );
  return data.data;
};
export const reportOrderProductReport = async (req) => {
  const { data } = await axios(
    `report/orderProductReport/${req.schoolID}/${req.initialDate}/${req.endDate}`
  );
  return data.data;
};
export const reportNewOrderReport = async (req) => {
  const { data } = await axios(
    `report/newOrderReport/${req.schoolID}/${req.initialDate}/${req.endDate}`
  );
  return data.data;
};
export const reportPendingProduct = async (req) => {
  const { data } = await axios(
    `report/pendingProduct/${req.schoolID}/${req.initialDate}/${req.endDate}`
  );
  return data.data;
};
export const addressAdd = async ({ signal, req }) => {
  const { data } = await axios.patch(`address/update`, req, { signal });
  return data.data;
};
export const masterStudentAdd = async ({ signal, req, isEditMode }) => {
  if (isEditMode) {
    const { data } = await axios.post(`master/student/update`, req, { signal });
    return data?.data;
  }
  const { data } = await axios.post(`master/student/add`, req, { signal });
  return data.data;
};

export const invoiceGet = async ({ queryKey, signal }) => {
  const { data } = await axios.post(`/invoice/view`, { signal });
  return data?.data[0] || {};
};


export const ticketsAdminUpdate = async ({ signal, req }) => {
  const { data } = await axios.post(
    `/tickets/admin/update?ticketID=${req.ticketID}&status=${req.status}`,
    { signal }
  );
  return data;
};
export const testCRONStatus = async (orderId) => {
  const { data } = await axios.post(`payments/razorpay/statusEnquiry`, {
    order_no: orderId, 
  });
  return data;
};


export const packageConstituents = async (id) => {
  const { data } = await axios(`package/constituents/${id}`);
  return data.data;
};

export const contactListupdate = async ({ signal, req }) => {
  const { data } = await axios.post("contactUs/admin/update", req, {
    signal,
  });
  return data;
};
export const packageAdminList = async ({ queryKey, signal }) => {
  const { data } = await axios("package/admin/list/" + queryKey[1], {
    signal,
  });
  return data.data;
};

export const packageAdminConstituents = async (id) => {
  const { data } = await axios("package/admin/constituents/" + id);
  return data.data;
};
export const cartAdminAdd = async ({ signal, req }) => {
  const { data } = await axios.post("cart/admin/add", req, { signal });
  return data;
};
