// routes
import { PATH_DASHBOARD } from "./routes/paths";

// API
// ----------------------------------------------------------------------

export const HOST_API = "https://rispl.backendapihub.com/";

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: "light",
  themeDirection: "ltr",
  themeColorPresets: "default",
  themeLayout: "horizontal",
  themeStretch: false,
};

export const deliveryOption = { school: 1, home: 2, either: 3 };

export const productStatusList = [
  // { label: "Deleted", value: 0 },
  // { label: "Cart", value: 1 },
  // { label: "Order Generated", value: 2 },
  // { label: "Processing", value: 3 }, 
  { label: "Created", value: 4 }, 
  { label: "Packed", value: 5 }, 
  { label: "Out For Delivery", value: 6 },
  { label: "Delivered", value: 7 }, 
  { label: "Partial Delivered", value: 8 },
  { label: "Cancel Order", value: 9 },
  { label: "RTO", value: 10 }, 
  { label: "Payment Failed", value: 11 }, 
  { label: "Refund", value: 12 }, 
];

// export const optionalType = [
//   { label: "Manadatory", value: 1 },
//   { label: "Optional", value: 2 },
//   { label: "Editable & Manadatory", value: 3 },
//   { label: "Editable & Optional", value: 4 },
// ];

export const optionalType = [
  { label: "Manadatory", value: 1 },
  { label: "Editable & Manadatory", value: 2 },
  { label: "Optional", value: 3 },
  { label: "Editable & Optional", value: 4 },
];