// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    schoolOrder: path(ROOTS_DASHBOARD, "/product-wise-order"),
    globalSearchOrder: path(ROOTS_DASHBOARD, "/global-search"),
    parentList: path(ROOTS_DASHBOARD, "/parent"),
    studenstList: path(ROOTS_DASHBOARD, "/students-list"),
    tickets: path(ROOTS_DASHBOARD, "/tickets"),
    orderStatus: path(ROOTS_DASHBOARD, "/order-status"),
    raisedTicket: path(ROOTS_DASHBOARD, "/enquiry-list"),
    adminOrder: path(ROOTS_DASHBOARD, "/place-order"),
  },

  master: {
    root: path(ROOTS_DASHBOARD, "/master"),
    category: path(ROOTS_DASHBOARD, "/master/category"),
    product: path(ROOTS_DASHBOARD, "/master/product"),
    description: path(ROOTS_DASHBOARD, "/master/description"),
    promoCode: path(ROOTS_DASHBOARD, "/master/promoCode"),
    variations: path(ROOTS_DASHBOARD, "/master/variations"),
    testimonial: path(ROOTS_DASHBOARD, "/master/testimonial"),
    pincode: path(ROOTS_DASHBOARD, "/master/pincode"),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    newReport: path(ROOTS_DASHBOARD, "/reports/new-report"),
    newAndPending: path(ROOTS_DASHBOARD, "/reports/new-and-pending-report"),
    pendingReport: path(ROOTS_DASHBOARD, "/reports/pending-report"),
    dateWiseReport: path(ROOTS_DASHBOARD, "/reports/date-wise-report"),
  },
};
