import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Autocomplete, TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFAutocomplete({
  name,
  children,
  option,
  placeholder,
  multiple,
  label,
  size = "small",
  onInputChange,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          // multiple={multiple}
          value={field.value || null}
          onChange={(_, value) => {
            field.onChange(value);
            if (onInputChange) {
              onInputChange(value);
            }
          }}
          options={option || []}
          fullWidth
          {...other}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder || "Search here..."}
              size={size}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  );
}


/*
const MyAutocomplete = () => {
  const [selectedValue, setSelectedValue] = useState(null);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name} // Display the name property
        onChange={(event, newValue) => {
          setSelectedValue(newValue ? newValue.value : null); // Set the value property on selection
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select an option" variant="outlined" />
        )}
      />
      <div>Selected Value: {selectedValue}</div>
    </div>
  );
};

export default MyAutocomplete;
*/