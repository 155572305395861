import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { OutlinedInput, Select, TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFSelectMulti.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};
export default function RHFSelectMulti({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          multiple
          
          input={<OutlinedInput  />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          {...other}
        >
          {children}
        </Select>
        // <TextField
        //   {...field}
        //   select
        //   fullWidth

        //   SelectProps={{ native: native ?? true }}
        //   error={!!error}
        //   helperText={error?.message}
        //   {...other}
        // >
        //   {children}
        // </TextField>
      )}
    />
  );
}
