import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import CreateOrder from "src/pages/create-order";
import ContactUS from "src/pages/enquiry-list/ContactUS";

// ----------------------------------------------------------------------

export const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        { path: "product-wise-order", element: <ProductOrder /> },
        { path: "global-search", element: <GlobalOrderSearch /> },
        { path: "parent", element: <ParentMaster /> },
        { path: "invoice", element: <InvoiceDetails /> },
        { path: "students-list", element: <StudentMaster /> },
        { path: "tickets", element: <ReportedIssue /> },
        { path: "enquiry-list", element: <ContactUS /> },
        { path: "order-status", element: <OrderStatus /> },
        { path: "place-order", element: <CreateOrder /> },
        {
          path: "master",
          children: [
            {
              element: <Navigate to="/dashboard/master/category" replace />,
              index: true,
            },
            { path: "category", element: <Category /> },
            { path: "product", element: <ProductMaster /> },
            { path: "promoCode", element: <PromocodeMaster /> },
            { path: "variations", element: <VariationMaster /> },
            { path: "description", element: <DescriptionMaster /> },
            { path: "testimonial", element: <TestimonialMaster /> },
            { path: "pincode", element: <PincodeMaster /> },
          ],
        },
        {
          path: "reports",
          children: [
            {
              element: <Navigate to="/dashboard/reports/new-report" replace />,
              index: true,
            },
            { path: "new-report", element: <NewOrder /> },
            {
              path: "new-and-pending-report",
              element: <NewAndPendingReport />,
            },
            { path: "pending-report", element: <PendingOrders /> },
            { path: "date-wise-report", element: <OrderedProductReport /> },
          ],
        },
      ],
    },

    // Main Routes

    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const ProductOrder = Loadable(lazy(() => import("src/pages/order")));

const Category = Loadable(lazy(() => import("src/pages/master/catgory")));
const ProductMaster = Loadable(lazy(() => import("src/pages/master/product")));
const PromocodeMaster = Loadable(
  lazy(() => import("src/pages/master/promo-code"))
);
const VariationMaster = Loadable(
  lazy(() => import("src/pages/master/variation"))
);
const TestimonialMaster = Loadable(
  lazy(() => import("src/pages/master/testimonial"))
);
const DescriptionMaster = Loadable(
  lazy(() => import("src/pages/master/description"))
);
const StudentMaster = Loadable(lazy(() => import("src/pages/master/students")));
const ParentMaster = Loadable(
  lazy(() => import("src/pages/master/parent-master"))
);
const NewAndPendingReport = Loadable(
  lazy(() => import("src/pages/reports/NewAndPendingReport"))
);
const OrderedProductReport = Loadable(
  lazy(() => import("src/pages/reports/OrderedProductReport"))
);
const NewOrder = Loadable(lazy(() => import("src/pages/reports/NewOrder")));
const PendingOrders = Loadable(
  lazy(() => import("src/pages/reports/PendingOrders"))
);

const GlobalOrderSearch = Loadable(
  lazy(() => import("src/pages/global-search"))
);
const InvoiceDetails = Loadable(
  lazy(() => import("src/pages/order/InvoiceDetails"))
);
const ReportedIssue = Loadable(lazy(() => import("src/pages/enquiry-list")));
const OrderStatus = Loadable(lazy(() => import("src/pages/order-status")));
const PincodeMaster = Loadable(lazy(() => import("src/pages/master/pincode")));
const RaisedTicket = Loadable(
  lazy(() => import("src/pages/enquiry-list/ContactUS"))
);
