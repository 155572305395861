import { Container, Grid, MenuItem } from "@mui/material";
import {
  Checkbox,
  InputAdornment,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
  cartAdminAdd,
  masterGetData,
  masterGetDataByid,
  packageAdminConstituents,
  packageAdminList,
} from "../api";
import RHFAutocomplete from "src/components/hook-form/RHFAutocomplete";
import { useSnackbar } from "notistack";
import { fString } from "src/utils/formatNumber";
import { LoadingButton } from "@mui/lab";

export function groupBy(array = [], key) {
  return array.reduce((result, currentItem) => {
    const group = currentItem[key];
    if (!result[group]) {
      result[group] = [];
    }

    result[group].push(currentItem);
    return result;
  }, {});
}

const defaultValues = {
  school: "",
  student: "",
  package: "",
  paymentMode: "",
  bankRefNo: "",
};
const schema = Yup.object().shape({
  paymentMode: Yup.string().trim().required("Required"),
  school: Yup.string().trim().required("Required"),
  student: Yup.object()
    .shape()
    .nullable()
    .typeError("Required")
    .required("Required"),
  package: Yup.object()
    .shape()
    .nullable()
    .typeError("Required")
    .required("Required"),
});
export default function CreateOrder() {
  const { themeStretch } = useSettings();

  const { enqueueSnackbar } = useSnackbar();
  const [packageDetail, setPackageDetail] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [isMendatory, setIsMendatory] = useState(false);
  const [isAllOptional, setIsAllOptional] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionalQty, setOptionalQty] = useState({});
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });

  const { handleSubmit, watch, reset, setValue } = methods;
  const schoolWatch = watch("school");
  const studentWatch = watch("student");
  const packageWatch = watch("package");
  const { data } = useQuery({
    queryKey: ["getData", "schools", 0, 1000],
    queryFn: masterGetData,
  });
  const { data: studentList, isLoading } = useQuery({
    queryKey: ["getData", "students", 0, 10000, "schoolID", schoolWatch],
    queryFn: masterGetDataByid,
    enabled: !!schoolWatch,
  });
  const { data: packageList, isLoading: isLoadingPackage } = useQuery({
    queryKey: ["packages", studentWatch?.id],
    queryFn: packageAdminList,
    enabled: !!studentWatch?.id,
  });

  const fetchPackageDetails = async (value) => {
    try {
      setLoading(true);
      const data = await packageAdminConstituents(value?.packageID);
      const groupedData = groupBy(data, "categoryName");
      setPackageDetail(groupedData);
      setIsMendatory(
        data.every((el) => el.optional === 1 || el?.optional === 2)
      );
      setIsAllOptional(
        data.every((el) => el.optional === 3 || el?.optional === 4)
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSingle = (el) => {
    const checkIndex = selectedPackage.findIndex(
      (item) => item.productID === el.productID
    );
    if (checkIndex === -1) {
      const newData = [...selectedPackage, el];
      setSelectedPackage(newData);
      setOptionalQty((ps) => ({ ...ps, [el.productID]: el.qty }));
    } else {
      const filterData = selectedPackage.filter(
        (item) => item.productID !== el.productID
      );
      setSelectedPackage(filterData);
      // setOptionalQty((ps) => ({ ...ps, [el.productID]: '' }));
    }
  };

  const handleChangeAll = (e) => {
    console.log(Object.values(packageDetail).flat(1));
    if (e.target.checked) {
      const selectedItem = [
        ...selectedPackage,
        ...Object.values(packageDetail)
          ?.flat(1)
          ?.filter((el) => el?.optional === 3 || el?.optional === 4),
      ];
      setSelectedPackage([...new Set(selectedItem)]);
      [...new Set(selectedItem)]?.forEach((el) => {
        setOptionalQty((ps) => ({ ...ps, [el.productID]: el?.qty }));
      });

      return;
    }
    setSelectedPackage([]);
    // setOptionalQty({});
  };

  const mutation = useMutation((req) => cartAdminAdd(req), {
    onSuccess: () => {
      enqueueSnackbar("Order has been created Please check in orders", {
        variant: "success",
      });
      setOptionalQty({});
      setSelectedPackage([]);
      reset();
      setPackageDetail(null);
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || "Failed to Add", { variant: "error" });
    },
  });

  const handleChangeQty = (e, item) => {
    const { value } = e.target;
    setOptionalQty((ps) => ({ ...ps, [item.productID]: value }));
  };
  useEffect(() => {
    Object?.values(packageDetail || {})
      ?.flat(1)
      .forEach((el) => {
        setOptionalQty((ps) => ({ ...ps, [el.productID]: el.qty }));
      });
  }, [packageDetail]);

  const onSubmit = (data) => {
    const optionalProducts =
      selectedPackage?.map((el) => ({
        productID: el.productID,
        qty: +optionalQty[el.productID],
      })) || [];
    if (isAllOptional && optionalProducts.length === 0) {
      enqueueSnackbar("Select a Product", { variant: "error" });
      return;
    }
    const filterData = Object?.values(packageDetail || {})
      ?.flat(1)
      .filter((el) => el.optional === 2);

    const mandatoryProducts = filterData.map((el) => ({
      productID: el.productID,
      qty: +optionalQty[el.productID],
    }));
    const req = {
      paymentMode: data.paymentMode,
      bankRefNo: data.bankRefNo,
      studentID: studentWatch?.id,
      packageID: packageWatch.packageID,
      optionalProducts,
      mandatoryProducts,
    };
    mutation.mutate({ req });
  };

  const optionalTotal = selectedPackage.reduce(
    (acc, crr) => acc + optionalQty[crr.productID] * crr?.price,
    0
  );
  const mendatoryPrice = Object?.values(packageDetail || {})
    ?.flat(1)
    ?.filter((el) => el.optional === 1)
    ?.reduce((acc, crr) => acc + crr?.amount, 0);
  const mendatoryEditable = Object?.values(packageDetail || {})
    ?.flat(1)
    ?.filter((el) => el.optional === 2)
    .reduce((acc, crr) => acc + optionalQty[crr.productID] * crr?.price, 0);

  // const onSubmit = () => {};

  return (
    <Page title="Master">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={`Create Order`}
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Order By Admin",
            },
            { name: "Create Order" },
          ]}
        />
        <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <RHFSelect
                name={"school"}
                native={false}
                size="small"
                label="Select School"
                onInputChange={() => {
                  setValue("student", "");
                  setValue("package", "");
                  setPackageDetail(null);
                }}
              >
                <MenuItem value="">Select School</MenuItem>
                {data &&
                  data?.data?.map((el) => (
                    <MenuItem key={el?.id} value={el?.id}>
                      {el?.schoolName}
                    </MenuItem>
                  ))}
              </RHFSelect>
            </Grid>
            <Grid item md={4}>
              <RHFAutocomplete
                name={"student"}
                size="small"
                label="Select Student"
                loading={isLoading}
                option={studentList?.data || []}
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option?.lastName}`
                }
                onInputChange={(value) => {
                  setValue("package", "");
                  setPackageDetail(null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item md={4}>
              <RHFAutocomplete
                name={"package"}
                size="small"
                label="Select Package"
                option={packageList || []}
                loading={isLoadingPackage}
                getOptionLabel={(option) => option.packageName}
                onInputChange={(value) => {
                  fetchPackageDetails(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.packageID === value.packageID
                }
              />
            </Grid>
          </Grid>
          <div>
            {packageDetail && (
              <>
                <TableContainer sx={{ mb: 2 }} component={Paper} elevation={2}>
                  <Table size={"medium"} stickyHeader>
                    <TableHead sx={{ mb: 1 }}>
                      <TableRow>
                        {!isMendatory && (
                          <TableCell>
                            <Checkbox
                              checked={
                                selectedPackage?.length ===
                                Object.values(packageDetail)
                                  ?.flat(1)
                                  ?.filter(
                                    (el) =>
                                      el?.optional === 3 || el?.optional === 4
                                  )?.length
                              }
                              onChange={handleChangeAll}
                              indeterminate={
                                selectedPackage > 0 &&
                                selectedPackage?.length <
                                  Object.values(packageDetail)?.flat(1)?.length
                              }
                              size="small"
                            />
                          </TableCell>
                        )}

                        <TableCell>PRODUCT</TableCell>

                        <TableCell>AUTHOR / PUB.</TableCell>
                        <TableCell>Single Qty Price.</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Total Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object?.keys(packageDetail)?.map((el, mainIndex) => (
                        <>
                          <TableRow key={el.productID + mainIndex}>
                            <TableCell
                              colSpan={6}
                              sx={{
                                background: (theme) => theme.palette.grey[300],
                              }}
                            >
                              <Typography variant="subtitle2">{el}</Typography>
                            </TableCell>
                          </TableRow>
                          {packageDetail &&
                            packageDetail[el].map((item, index) => (
                              <TableRow key={index}>
                                {!isMendatory && (
                                  <TableCell>
                                    {(item.optional === 3 ||
                                      item.optional === 4) && (
                                      <Checkbox
                                        onChange={() => {
                                          handleChangeSingle(item);
                                        }}
                                        checked={
                                          selectedPackage?.indexOf(item) !== -1
                                        }
                                        key={
                                          item.productID +
                                          item.index +
                                          item.productID
                                        }
                                        size="small"
                                      />
                                    )}
                                  </TableCell>
                                )}

                                <TableCell>{item.productName}</TableCell>

                                <TableCell>{item.publicationName}</TableCell>
                                <TableCell>
                                  <div className="style-4">
                                    {item.discountPercent > 0 && (
                                      <>
                                        <del>
                                          <span className="amount">
                                            ₹{" "}
                                            {(
                                              item.price /
                                              (1 - item.discountPercent / 100)
                                            ).toFixed(0)}{" "}
                                          </span>
                                        </del>
                                        <br />
                                      </>
                                    )}

                                    {/* {item.discountPercent > 0 && <span>{item.discountPercent} % Discount</span>} */}
                                    <ins>
                                      <span className="amount">
                                        ₹{fString(item.price)}
                                        {item.discountPercent > 0 && (
                                          <Typography
                                            variant="h6"
                                            color={"green"}
                                          >
                                            {" "}
                                            {item.discountPercent} % Discount
                                          </Typography>
                                        )}
                                      </span>
                                    </ins>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {item.optional === 2 ||
                                  item.optional === 4 ? (
                                    <TextField
                                      type="number"
                                      size="small"
                                      label="Qty"
                                      sx={{ width: "180px" }}
                                      disabled={
                                        selectedPackage.indexOf(item) === -1 &&
                                        item.optional === 4
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{
                                        min: item?.minQty || 1,
                                        max: item?.maxQty || item?.qty,
                                      }}
                                      value={optionalQty[item.productID]}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            {item.maxQty && (
                                              <Typography variant="subtitle2">
                                                of {item.maxQty}
                                              </Typography>
                                            )}
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) => {
                                        handleChangeQty(e, item);
                                      }}
                                      fullWidth
                                      onBlur={(e) => {
                                        e.preventDefault();
                                        console.log("onblur");
                                        if (
                                          optionalQty[item.productID] === ""
                                        ) {
                                          setOptionalQty((ps) => ({
                                            ...ps,
                                            [item.productID]: 0,
                                          }));
                                        }
                                      }}
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </TableCell>

                                <TableCell sx={{ width: 200 }}>
                                  <span className="amount">
                                    ₹
                                    {fString(
                                      optionalQty[item.productID] * item.price
                                    )}
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={isMendatory ? 4 : 5}>
                          {!isMendatory && (
                            <Typography
                              variant="subtitle2"
                              fontWeight={600}
                              fontSize={18}
                            >
                              Optional Selected Item :{" "}
                              {selectedPackage?.length || 0}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            fontWeight={700}
                            fontSize={20}
                          >
                            Total : ₹{" "}
                            {fString(
                              optionalTotal +
                                mendatoryPrice +
                                mendatoryEditable || 0
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>

                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <RHFSelect
                      name={"paymentMode"}
                      native={false}
                      size="small"
                      label="Select Payment mode"
                    >
                      <MenuItem value="">Select Payment Mode</MenuItem>
                      <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
                      <MenuItem value={"Debit Card"}>Debit Card</MenuItem>
                      <MenuItem value={"Net Banking"}>Net Banking</MenuItem>
                      <MenuItem value={"UPI"}>UPI</MenuItem>
                      <MenuItem value={"cash"}>Cash</MenuItem>
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={3}>
                    <RHFTextField
                      label="Bank Ref No/Transaction Id"
                      name={"bankRefNo"}
                      placeholder="Bank Ref No, Transaction Id"
                      size="small"
                    />
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      // disabled={mutation.isLoading}
                      loading={mutation.isLoading}
                      type="submit"
                    >
                      Create Order
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            )}
            {loading && <LinearProgress />}
          </div>
        </FormProvider>
      </Container>
    </Page>
  );
}
