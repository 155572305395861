import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";
import { MenuProps } from "./RHFSelectMulti";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect({
  name,
  children,
  native,
  onInputChange,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          // {...field}
          value={field.value}
          onChange={(e) => {
            field.onChange(e);
            if (onInputChange) {
              onInputChange(e);
            }
          }}
          select
          fullWidth
          SelectProps={{
            native: native ?? true,
            MenuProps: MenuProps,
            sx: {
              "&.MuiButtonBase-root": {
                whiteSpace: "pre-wrap",
              },
            },
          }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
